import React from 'react';
import BorderCards from '../component/BorderCards';

const PrivacyPolicy = () => {
  return (
    <div>
      <div
        style={{
          background:
            'linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)',
        }}
      >
        <p className='section-container-title'>Motomtech Privacy police</p>
        <BorderCards
          borderCardsText1={'Your privacy is of paramount importance to us.'}
          borderCardsText2={
            'This Privacy Policy explains how we collect, use, and protect your personal information when you visit our website or use our services. By using our website, you agree to the collection and use of information in accordance with this policy.'
          }
          borderCardsText3={'Information Collection and Use'}
          borderCardsText4={
            "While using our website, we may ask you to provide certain personal information, such as your name, email address, and phone number. We use this information to:Provide and improve our services, Communicate with you, Customize your experience, Send promotional materials or newsletters (if you opt-in), Comply with legal requirements, We also collect non-personally identifiable information, including your IP address, browser type, and pages visited, to improve our website's functionality and analyze usage trends. "
          }
          borderCardsText5={'Cookies'}
          borderCardsText6={
            'Our website uses "cookies" to enhance user experience. Cookies are small files stored on your computer or device that help us recognize you and remember your preferences. You may refuse cookies through your browser settings, but this may affect your website experience.'
          }
          borderCardsText7={'Data Security'}
          borderCardsText8={
            'We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, or destruction. However, no method of transmission or electronic storage is 100% secure, and we cannot guarantee its absolute security.'
          }
          borderCardsText9={'Data Retention'}
          borderCardsText10={
            'We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.'
          }
          borderCardsText11={'Third-Party Services'}
          borderCardsText12={
            'We may employ third-party services for various purposes, such as payment processing or analytics. These third parties have access to your personal information only to perform specific tasks and are obligated not to disclose or use it for any other purpose.'
          }
          borderCardsText13={'Links to Other Sites'}
          borderCardsText14={
            'Our website may contain links to external sites that are not operated by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites'
          }
          borderCardsText15={"Children's Privacy"}
          borderCardsText16={
            'We do not knowingly collect personal information from children under 13. If we discover that a child under 13 has provided us with personal information, we will promptly delete it.'
          }
          borderCardsText17={'Changes to This Privacy Policy'}
          borderCardsText18={
            'We reserve the right to update or change our Privacy Policy at any time. Your continued use of our website after any modifications to this policy constitutes your acceptance of the updated policy.'
          }
          borderCardsText19={'Contact Us'}
          borderCardsText20={
            'If you have any questions or concerns about our Privacy Policy, please contact us at (insert contact information). By using our website, you consent to our Privacy Policy and agree to its terms'
          }
        />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
